import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[_c('h1',{staticClass:"pageHeader my-12"},[_vm._v("Create Admin")]),_c('div',{staticClass:"mb-5"},[_vm._v("Admin Info")]),_c(VForm,{ref:"createAdminForm",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,{staticClass:"mb-12 pb-12",attrs:{"no-gutters":""}},_vm._l((_vm.formFields),function(form,index){return _c(VCol,{key:index,attrs:{"cols":"12","md":"6"}},[(form.field !== 'other')?_c('AppFormField',{staticClass:"me-5",attrs:{"rules":form.rules,"label":_vm.$t(("label." + (form.label))),"outlined":"","dark":""},model:{value:(_vm.formValues[form.key]),callback:function ($$v) {_vm.$set(_vm.formValues, form.key, $$v)},expression:"formValues[form.key]"}}):_vm._e(),(form.key === 'mobile')?_c('vue-tel-input-vuetify',{staticClass:"me-5",attrs:{"preferredCountries":['SG', 'MY'],"defaultCountry":"SG","label":"Mobile No. (Optional)","mode":"","outlined":"","dark":"","clearable":"","rules":_vm.formValidation.mobileNumberRules(
              _vm.formValues.mobileDialCode,
              _vm.formValues.mobile
            )},on:{"input":_vm.onNumberChange,"country-changed":_vm.updateCountry},model:{value:(_vm.formValues.mobile),callback:function ($$v) {_vm.$set(_vm.formValues, "mobile", $$v)},expression:"formValues.mobile"}}):_vm._e()],1)}),1)],1),_c('div',{staticClass:"text-center my-12 pb-12"},[_c(VBtn,{staticClass:"btn1",attrs:{"disabled":!_vm.valid},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Create Admin ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }