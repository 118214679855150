<template>
  <div class="content-wrapper">
    <h1 class="pageHeader my-12">Create Admin</h1>
    <div class="mb-5">Admin Info</div>
    <v-form v-model="valid" ref="createAdminForm">
      <v-row no-gutters class="mb-12 pb-12">
        <v-col
          cols="12"
          md="6"
          v-for="(form, index) in formFields"
          :key="index"
        >
          <AppFormField
            v-if="form.field !== 'other'"
            class="me-5"
            :rules="form.rules"
            v-model="formValues[form.key]"
            :label="$t(`label.${form.label}`)"
            outlined
            dark
          />
          <vue-tel-input-vuetify
            v-if="form.key === 'mobile'"
            :preferredCountries="['SG', 'MY']"
            class="me-5"
            defaultCountry="SG"
            v-model="formValues.mobile"
            label="Mobile No. (Optional)"
            mode=""
            outlined
            dark
            clearable
            @input="onNumberChange"
            @country-changed="updateCountry"
            :rules="
              formValidation.mobileNumberRules(
                formValues.mobileDialCode,
                formValues.mobile
              )
            "
          ></vue-tel-input-vuetify>
        </v-col>
      </v-row>
    </v-form>
    <div class="text-center my-12 pb-12">
      <v-btn class="btn1" @click="submit()" :disabled="!valid">
        Create Admin
      </v-btn>
    </div>
  </div>
</template>

<script>
  import { formValidationHelper } from '@/utils';
  import {
    PROFILE_INITIAL_CREATE_ADMIN_STATE,
    PROFILE_CREATE_ADMIN
  } from '@/store/profile.module';

  export default {
    name: 'CreateAdmin',
    data: () => ({
      formValues: {
        name: '',
        firstName: '',
        lastName: '',
        designation: '',
        mobileDialCode: '+60',
        mobile: '',
        email: ''
      },
      valid: false,
      formValidation: formValidationHelper
    }),
    computed: {
      formFields() {
        return [
          {
            key: 'name',
            label: 'displayName',
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'firstName',
            label: 'firstName',
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'lastName',
            label: 'lastName',
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'mobile',
            label: 'mobileNo',
            field: 'other'
          },
          {
            key: 'email',
            label: 'email',
            rules: formValidationHelper.emailRules()
          },
          {
            key: 'designation',
            label: 'position',
            rules: formValidationHelper.requiredRules()
          }
        ];
      },
      createAdminComplete() {
        return this.$store.state.profile.createAdmin.complete;
      }
    },
    watch: {
      createAdminComplete() {
        let response = this.$store.state.profile.createAdmin;
        if (response.complete) {
          if (response.code === 200) {
            this.$router.push({
              path: '/admin-account',
              params: {
                createdAdmin: '1'
              }
            });
          } else {
            this.openAppDialogInfo(
              'error',
              response.message || 'Request Failed',
              '',
              [
                {
                  text: 'OK',
                  action: () => {
                    this.closeAppDialogInfo();
                  }
                }
              ]
            );
          }
          this.initialCreateAdminState();
        }
      }
    },
    methods: {
      onNumberChange(v, v2) {
        this.formValues.mobile = v2.number.significant || v2.number.input;
      },
      updateCountry(event) {
        this.formValues.mobileDialCode = '+' + event.dialCode;
      },
      submit() {
        this.$store.dispatch(PROFILE_CREATE_ADMIN, { data: this.formValues });
      },
      initialCreateAdminState() {
        this.$store.dispatch(PROFILE_INITIAL_CREATE_ADMIN_STATE);
      }
    }
  };
</script>

<style scoped lang="scss"></style>
